html {
  --backgroundColor: #FAFAFA;
  --borderRadius: 3px;
  --font: 'Jost', sans-serif;
  --fontWeightL: 700;
  --fontSizeM: 16px;
  --fontSizeL: 18px;
  --fontSizeXL: 30px;

  --fontColorM: rgba(0,0,0,0.8);
  --fontColorL: rgba(0,0,0,0.6);
  --fontColorXL: #595448;

  --primaryColor: #6200EE;
  --focusColor: rgba(137,62,255,0.1);
  --mainBorderColor : #DEDDE0;
  --errorColor: #C50000;
  --inputBorderColor: #757575;
  --gray: #979797;

  --buttonBackground: white;
  --buttonColor: #6200EE;
  --buttonBackgroundHover: rgba(98,0,238,0.1);
  --primaryButtonBackground: #6200EE;
  --primaryButtonColor: white;
  --primaryButtonBackgroundHover: rgba(98,0,238,0.85);

  font-family: var(--font);
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--fontColorM);
  font-family: var(--font);
}
